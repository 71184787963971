<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <SmallSquare content="1、小程序推送模板仅针对云店商城的消费者进行消息推送
      2、启用的推送模板品牌端可以选择是否开启推送服务
      3、禁用的推送模板品牌端无法查看选择
      4、模板的配置需要技术人员参与，请谨慎操作" />
      <a-button class="m-b-20 m-t-20" type="primary" v-has:miniTemplateAdd="()=> {$router.push('/brandMsg/miniTemplate/add?type=add')}">新建小程序推送模板</a-button>
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search"
        :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="关键词" name='keywords'>
          <a-input v-model:value="search.keywords" style="width:270px" placeholder="请输入标题进行搜索"></a-input>
        </a-form-item>
        <a-form-item label="状态" name='status'>
          <a-select v-model:value="search.status" :style="{width:'180px'}" placeholder="全部">
            <a-select-option value="">全部</a-select-option>
            <a-select-option :value="1">启用</a-select-option>
            <a-select-option :value="0">禁用</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a v-has:miniTemplateEdit="()=> {$router.push('/brandMsg/miniTemplate/edit?type=edit&id='+record.id)}" href="javascript:;">编辑</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:miniTemplateStatus="()=>statusSms(record)" href="javascript:;">{{record.status ? '禁用':'启用'}}</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:miniTemplateDel="()=>delSms(record)" href="javascript:;">删除</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper
        :current="search.page" :page-size="search.size" :total="total" @change="pageCurrentChange"
        @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
</template>
<script>
  import { DownOutlined } from "@ant-design/icons-vue";
  import { ref, onMounted, reactive, toRefs, inject } from "vue";
  import { message, Model } from "ant-design-vue";
  import { getRouter } from "@/utils/app";
  import { setPageState, getPageState } from "@/utils/pageState";
  import { getMiniAppList, miniAppStatus, miniAppSysDel } from "@/api/message";
  import { $iscode } from "@/utils/app";
  // 初始化默认筛选项数值
  let defSearch = {
    keywords: "",
    status: "",
    page: 1,
    limit: 10,
  };
  export default {
    components: { DownOutlined },
    setup(props) {
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([]);
      const state = reactive({
        listLoading: false,
        total: 1,
        levelList: [],
      });
      let columns = [
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "关键词",
          dataIndex: "tmp_key",
          key: "tmp_key",
        },
        {
          title: "使用场景说明",
          dataIndex: "sceneDesc",
          key: "sceneDesc",
        },
        {
          title: "状态",
          dataIndex: "status_text",
          key: "status_text"
        },
        {
          title: "操作",
          key: "action",
          width:120,
          fixed:"right",
          slots: { customRender: "action" },
        },
      ];
      const $Modal = inject('$Modal')
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData(search.value);
      };
      //启用禁用
      const statusSms = (e) => {
        $Modal.confirm({
          centered: true,
          title: '提示',
          content: '确定'+(e.status ? '禁用':'启用')+'当前短信模板吗？', onOk: async () => {
            let res = await miniAppStatus(e.id).then(res => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData(search.value);
            } else {
              message.error(res.msg);
            }
          }
        })
      }
      //删除
      const delSms = (e) => {
        $Modal.confirm({
          centered: true,
          title: '提示',
          content: '确定删除当前短信模板吗？', onOk: async () => {
            let res = await miniAppSysDel(e.id).then(res => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData(search.value);
            } else {
              message.error(res.msg);
            }
          }
        })
      }
      //获取列表
      const initData = async (values) => {
        data.value = []
        state.listLoading = true;
        try {
          let res = await getMiniAppList(values).then(res => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
            state.total = res.data.total;
          } else {
            message.error(res.msg);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      onMounted(() => {
        // 查看是否有留存状态，有则替换
        let pageState = getPageState(getRouter().route.path);
        if (pageState) {
          search.value = Object.assign(search.value, pageState);
        }
        initData(search.value);
      });
      return {
        ...toRefs(state),
        search,
        data,
        columns,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        delSms,
        statusSms
      };
    },
  };
</script>